import { Tenant } from '@ps-monorepo/api-model';
import httpClient from '../utilities/http-client';

/**
 * Service related to current tenant context
 */
export class TenantService {
  /**
   * Get info about the tenant, including current school year and state/country codes
   */
  public static async getTenant(): Promise<Tenant> {
    // Get user
    const { data } = await httpClient.get('/api/sis/tenant');
    return data;
  }
}
