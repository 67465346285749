export const SFRStatusIcons = new Map([
  ['START', 'calendar-custom'],
  ['ERROR', 'exclamation'],
  ['SUCCESS', 'proctoring-complete']
]);

export const SFRIconColors = new Map([
  ['START', ''],
  ['ERROR', 'orange'],
  ['SUCCESS', 'green']
]);

export const SFRIncomingStatusDisplayNames = new Map([
  ['START', 'START'],
  ['ERROR', 'ERROR'],
  ['SUCCESS', 'SUCCESS']
]);
