export const STXStatusIcons = new Map([
  ['PROCESSING', 'inprogress'],
  ['SCHEDULED', 'calendar'],
  ['REJECTED', 'x-alt'],
  ['COMPLETED', 'checkmark-alt'],
  ['POSTED', 'inprogress'],
  ['SEEN', 'inprogress'],
  ['RECEIVED', 'inprogress'],
  ['ERROR', 'exclamation'],
  ['PENDING', ''],
  ['ASSIGNED', 'user']
]);

export const STXIconColors = new Map([
  ['OPEN', 'orange'],
  ['PROCESSING', ''],
  ['SCHEDULED', ''],
  ['REJECTED', 'red'],
  ['COMPLETED', 'green'],
  ['POSTED', ''],
  ['SEEN', ''],
  ['RECEIVED', ''],
  ['ERROR', 'orange'],
  ['PENDING', ''],
  ['ASSIGNED', 'blue']
]);

export const STXIncomingStatusDisplayNames = new Map([
  ['PENDING', 'PENDING'],
  ['ASSIGNED', 'ASSIGNED'],
  ['SCHEDULED', 'SCHEDULED'],
  ['PROCESSING', 'PROCESSING'],
  ['ERROR', 'ERROR'],
  ['COMPLETED', 'COMPLETED'],
  ['REJECTED', 'REJECTED'],
  ['GENERATING_RESPONSE', 'PROCESSING'],
  ['VALIDATING', 'PROCESSING'],
  ['WAIT_FOR_DISPATCH', 'PROCESSING'],
  ['WAIT_FOR_ACK', 'PROCESSING'],
  ['INTERNAL_ERROR', 'ERROR'],
  ['SYSTEM_ERROR', 'ERROR'],
  ['GENERATING_REJECT', 'REJECTED'],
  ['WAIT_FOR_DISPATCH_REJECT', 'REJECTED'],
  ['WAIT_FOR_ACK_REJECT', 'REJECTED']
]);

export const STXOutgoingStatusDisplayNames = new Map([
  ['PROCESSING', 'PROCESSING'],
  ['SCHEDULED', 'SCHEDULED'],
  ['POSTED', 'POSTED'],
  ['REJECTED', 'REJECTED'],
  ['RECEIVED', 'RECEIVED'],
  ['COMPLETED', 'COMPLETED'],
  ['WAIT_FOR_DISPATCH', 'PROCESSING'],
  ['PENDING', 'PROCESSING'],
  ['SEEN', 'PROCESSING'],
  ['GENERATING_PDF', 'PROCESSING'],
  ['ATTACHING_TO_SIS', 'PROCESSING'],
  ['ERROR', 'REJECTED']
]);

export const STXRequestTypes = new Map([
  ['I', 'Interdistrict (I00)'],
  ['S', 'Secondary to Postsecondary (S00)'],
  ['P', 'Postsecondary (P00)']
]);
