import {
  API_PARAM_LIMIT,
  API_PARAM_SEARCH_TEXT,
  API_PARAM_USER_ID
} from '@ps-monorepo/api-constants';
import { User } from '@ps-monorepo/api-model';
import httpClient from '../utilities/http-client';

/**
 * Service related to current user info and session
 */
export class UserService {
  /**
   * Log out of authenticated server-side session
   *
   * @param logMessage
   */
  public static async logout(logMessage: string): Promise<void> {
    // POST logout (server-side will handle invalidation of auth user session)
    try {
      await httpClient.post('/api/logout');
      console.log(logMessage);
    } catch (error) {
      console.error('Error while signing out', error);
    }
  }

  /**
   * Get info about the current user, or else a specific user if user identifier is specified
   *
   * @param userId
   */
  public static async getUser(userId?: string): Promise<User> {
    // Request config (ex. params)
    let config = {};

    // Add user identifier to query string params if provided
    if (userId) {
      // Parameter names must be consistent with server side
      const params = { [API_PARAM_USER_ID]: userId };
      config = { params };
    }

    // Get user
    const { data } = await httpClient.get('/api/sis/user', config);
    return data;
  }

  /**
   * Get list of users for the tenant/district
   *
   * @param searchText
   * @param limit
   * @param userId
   */
  public static async getUsers(
    searchText: string,
    limit: number,
    userId?: string
  ): Promise<Array<User>> {
    // Parameter names must be consistent with server side
    const params = {
      [API_PARAM_SEARCH_TEXT]: searchText,
      [API_PARAM_LIMIT]: limit,
      [API_PARAM_USER_ID]: userId
    };
    const { data } = await httpClient.get('/api/sis/users', { params });
    return data;
  }
}
