import { WebSocketMessage } from '@ps-monorepo/api-model';

/**
 * Generate web socket message
 *
 * @param action
 * @param data
 */
export function generateWebSocketMessage(action: string, data: any): WebSocketMessage {
  return { action, data };
}
