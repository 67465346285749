import {
  AllStateCodes,
  API_PARAM_COUNTRY,
  API_PARAM_STX_CODES_LIMIT,
  API_STX_DEFAULT_LIMIT,
  API_PARAM_STATE,
  API_PARAM_REQUEST_ID,
  API_PARAM_TRANSCRIPT_EXCHANGE_ID,
  CountryCodes
} from '@ps-monorepo/api-constants';
import {
  LabelStateOverride,
  Student,
  STXIncomingRequestDetailsPage,
  STXIncomingRequestDisposition,
  STXOutgoingRequestDetailsPage,
  STXStatus,
  GridWidgetRestArgParams,
  STXCodeSet
} from '@ps-monorepo/api-model';
import httpClient from '../utilities/http-client';

/**
 * Service to get data from Student Transcript Exchange (STX) REST APIs
 */
export class STXService {
  /**
   * Create new STX outgoing request
   *
   * @param data
   * @param requestingInstitutionCode
   * @param requestingInstitutionName
   * @param addressedInstitutionCode
   * @param addressedInstitutionName
   * @param requestCode
   * @param requestType
   */
  public static async postSTXRequest(
    data: Array<Student>,
    requestingInstitutionCode: string,
    requestingInstitutionName: string,
    addressedInstitutionCode: string,
    addressedInstitutionName: string,
    requestCode: string,
    requestType: string
  ): Promise<void> {
    // Create JSON body to send
    const body = JSON.stringify({
      data,
      requestingInstitutionCode,
      requestingInstitution: requestingInstitutionName,
      addressedInstitutionCode,
      addressedInstitution: addressedInstitutionName,
      requestCode,
      requestType
    });
    // Call API to create a new STX outgoing request
    await httpClient.post('/api/stx/request/outgoing', body);
  }

  /**
   * Get STX status
   */
  public static async getSTXStatus(): Promise<STXStatus & LabelStateOverride> {
    const { data } = await httpClient.get('/api/stx/status');
    return data;
  }

  /**
   * Set disposition of STX incoming request as either approved, rejected or assigned
   *
   * @param incomingRequestDisposition
   */
  public static async putSTXIncomingRequest(
    incomingRequestDisposition: STXIncomingRequestDisposition
  ): Promise<void> {
    try {
      // Use a PUT since we do not intend to create a new record whenever sending for the same
      // parameters, only updating an existing record
      await httpClient.put('/api/stx/request/incoming', incomingRequestDisposition);
    } catch (error) {
      console.error('Error while posting STX incoming request match', error);
    }
  }

  /**
   * Get set of STX outgoing request details
   *
   * @param body
   */
  public static async getSTXOutgoingRequestDetailsPage(body: {
    pageSize: number,
    pageNumber: number,
    sortArgs: GridWidgetRestArgParams[],
    filterArgs: GridWidgetRestArgParams[],
    schoolSelection: string
  }): Promise<STXOutgoingRequestDetailsPage> {
    // Use a POST since we need to pass parameters in body in order to avoid possible sensitive
    // data being recorded in access logs
    const { data } = await httpClient.post('/api/stx/requests/outgoing', body);
    return data;
  }

  /**
   * Get set of STX outgoing request pdf details
   *
   * @param requestId
   * @param transcriptExchangeId
   */
  public static async getOutgoingRequestPdf(
    requestId: string,
    transcriptExchangeId: string
  ): Promise<void> {
    // Parameter names must be consistent with server side
    const params = {
      [API_PARAM_REQUEST_ID]: requestId,
      [API_PARAM_TRANSCRIPT_EXCHANGE_ID]: transcriptExchangeId
    };

    try {
      // GET request to retrieve a PDF from the specified endpoint with the provided params
      const { data } = await httpClient.get('/api/stx/request/outgoing/pdf', { params });
      return data;
    } catch (error) {
      return error;
    }
  }

  /**
   * Get set of STX incoming request details
   *
   * @param body
   */
  public static async getSTXIncomingRequestDetailsPage(body: {
    pageSize: number,
    pageNumber: number,
    sortArgs: GridWidgetRestArgParams[],
    filterArgs: GridWidgetRestArgParams[],
    schoolSelection: string
  }): Promise<STXIncomingRequestDetailsPage> {
    // Use a POST since we need to pass parameters in body in order to avoid possible sensitive
    // data being recorded in access logs
    const { data } = await httpClient.post('/api/stx/requests/incoming', body);
    return data;
  }

  /**
   * Get STX Code Sets for a specific country and state.
   *
   * @param {CountryCodes} country
   * @param {AllStateCodes} state
   * @param {number} limit
   * @returns
   */
  public static async getSTXCodes(
    country: CountryCodes,
    state: AllStateCodes,
    limit?: number
  ): Promise<STXCodeSet> {
    // Parameter names must be consistent with server side
    const params = {
      [API_PARAM_COUNTRY]: country,
      [API_PARAM_STATE]: state,
      [API_PARAM_STX_CODES_LIMIT]: limit || API_STX_DEFAULT_LIMIT
    };

    // Make API Call to STX Codes
    const { data } = await httpClient.get('/api/stx/codes', { params });
    return data;
  }

  /**
   * Get STX resposne codes for a specific country and state.
   *
   * @param {CountryCodes} country
   * @param {AllStateCodes} state
   * @param {number} limit
   * @returns
   */
  public static async getSTXResponseCodes(
    country: CountryCodes,
    state: AllStateCodes,
    limit?: number
  ): Promise<STXCodeSet> {
    // Parameter names must be consistent with server side
    const params = {
      [API_PARAM_COUNTRY]: country,
      [API_PARAM_STATE]: state,
      [API_PARAM_STX_CODES_LIMIT]: limit || API_STX_DEFAULT_LIMIT
    };

    // Make API Call to STX Codes
    const { data } = await httpClient.get('/api/stx/codes/response', { params });
    return data;
  }

  /**
   * Get STX Code Sets for a specific country and state.
   *
   * @param {CountryCodes} country
   * @param {AllStateCodes} state
   * @param {number} limit
   * @returns
   */
  public static async getSTXRequestTypes(
    country: CountryCodes,
    state: AllStateCodes,
    limit?: number
  ): Promise<STXCodeSet> {
    // Parameter names must be consistent with server side
    const params = {
      [API_PARAM_COUNTRY]: country,
      [API_PARAM_STATE]: state,
      [API_PARAM_STX_CODES_LIMIT]: limit || API_STX_DEFAULT_LIMIT
    };

    // Make API Call to STX Codes
    const { data } = await httpClient.get('/api/stx/request-types', { params });
    return data;
  }
}
